(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _marked = require("./common/marked.js");

var _marked2 = _interopRequireDefault(_marked);

var _Navigation = require("./components/Navigation/Navigation.jsx");

var _Navigation2 = _interopRequireDefault(_Navigation);

var _ContentfulImg = require("./components/ContentfulImg/ContentfulImg.jsx");

var _ContentfulImg2 = _interopRequireDefault(_ContentfulImg);

var _Home = require("./pages/Home/Home.jsx");

var _Home2 = _interopRequireDefault(_Home);

var _TheFilm = require("./pages/TheFilm/TheFilm.jsx");

var _TheFilm2 = _interopRequireDefault(_TheFilm);

var _Screenings = require("./pages/Screenings/Screenings.jsx");

var _Screenings2 = _interopRequireDefault(_Screenings);

var _Filmmakers = require("./pages/Filmmakers/Filmmakers.jsx");

var _Filmmakers2 = _interopRequireDefault(_Filmmakers);

var _Experts = require("./pages/Experts/Experts.jsx");

var _Experts2 = _interopRequireDefault(_Experts);

var _Faqs = require("./pages/Faqs/Faqs.jsx");

var _Faqs2 = _interopRequireDefault(_Faqs);

var _Facts = require("./pages/Facts/Facts.jsx");

var _Facts2 = _interopRequireDefault(_Facts);

var _Press = require("./pages/Press/Press.jsx");

var _Press2 = _interopRequireDefault(_Press);

var _TextPage = require("./pages/TextPage/TextPage.jsx");

var _TextPage2 = _interopRequireDefault(_TextPage);

var _scrollHelpers = require("./common/scrollHelpers.js");

var _reactRouterDom = require("react-router-dom");

var _routes = require("./routes.js");

var _propTypes = require("prop-types");

var _propTypes2 = require("./common/propTypes.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var App = function (_React$Component) {
  _inherits(App, _React$Component);

  function App() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, App);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = App.__proto__ || Object.getPrototypeOf(App)).call.apply(_ref, [this].concat(args))), _this), _this.getChildContext = function () {
      return {
        heroImage: _this.props.homePage.heroImage
      };
    }, _this.componentDidMount = function () {
      // make sure the page is rendered first
      window.requestAnimationFrame(function () {
        _this.handleRouteChange();
      });

      _this.updateDocumentTitle();
    }, _this.componentDidUpdate = function (prevProps) {
      _this.handleRouteChange();
      _this.updateDocumentTitle();
    }, _this.updateDocumentTitle = function () {
      document.title = (0, _routes.pathnamePageTitle)(_this.props.location.pathname);
    }, _this.handleRouteChange = function () {
      var hash = _ramda2.default.path(["location", "hash"], _this.props);

      // if there is a hash in the url, scroll to it
      if (hash) {
        var el = document.querySelector(hash);
        var mobileSectionNav = document.querySelector(".js-mobile-section-nav");
        var mobileNav = document.querySelector(".js-mobile-nav");
        var desktopNav = document.querySelector(".js-desktop-nav");

        if (el) {
          var getMobileNavOffset = _ramda2.default.tryCatch(
          // get the position of the mobile nav
          function () {
            var mobileSectionNavHeight = mobileSectionNav.getBoundingClientRect().height;

            // some HACK-ery because the nav moves up and down depending on the
            // scroll direction.  We need to predict it's position when this
            // is done scrolling
            var mobileNavHeight = (0, _scrollHelpers.getOffsetTop)(el) - (0, _scrollHelpers.getScrollTop)() < 0 ? mobileNav.getBoundingClientRect().height : 0;

            return mobileNavHeight + mobileSectionNavHeight;
          }, function () {
            return 0;
          });

          var getDesktopNavOffset = _ramda2.default.tryCatch(function () {
            return desktopNav.getBoundingClientRect().height;
          }, function () {
            return 0;
          });

          // defer these calls until the page has completely changed
          // for some reason, getOffsetTop(el) isn't accurate immediately after
          // rendering a new page
          window.requestAnimationFrame(function () {
            var offsetTop = mobileNav ? getMobileNavOffset() : desktopNav ? getDesktopNavOffset() : 0;

            (0, _scrollHelpers.scrollTo)((0, _scrollHelpers.getOffsetTop)(el) - offsetTop);
          });
        }
      }
      // if the user clicked the footer nav or some other link mid-page,
      // we  want to jump to the top
      else {
          window.scrollTo(0, 0);
        }
    }, _this.render = function () {
      var _this$props = _this.props,
          homePage = _this$props.homePage,
          trailer = _this$props.trailer,
          synopsis = _this$props.synopsis,
          billingBlock = _this$props.billingBlock,
          personList = _this$props.personList,
          photoGallery = _this$props.photoGallery,
          location = _this$props.location,
          footer = _this$props.footer,
          faqPage = _this$props.faqPage,
          pressPage = _this$props.pressPage,
          factsPage = _this$props.factsPage,
          theFilmPage = _this$props.theFilmPage,
          screeningsPage = _this$props.screeningsPage;


      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(_Navigation2.default, { location: location }),
        _react2.default.createElement(
          "div",
          { className: "content" },
          _react2.default.createElement(
            _reactRouterDom.Switch,
            null,
            _react2.default.createElement(_reactRouterDom.Route, {
              exact: true,
              path: "/",
              render: function render(_ref2) {
                var match = _ref2.match;
                return _react2.default.createElement(_Home2.default, _extends({}, homePage, trailer, {
                  synopsis: synopsis,
                  events: screeningsPage.events
                }));
              }
            }),
            _react2.default.createElement(_reactRouterDom.Route, {
              exact: true,
              path: "/the-film",
              render: function render(_ref3) {
                var match = _ref3.match;
                return _react2.default.createElement(_TheFilm2.default, {
                  heroImage: theFilmPage.heroImage,
                  synopsis: synopsis.synopsisLong,
                  imageInline: synopsis.imageInline,
                  filmStills: photoGallery.filmStills,
                  filmmakers: _ramda2.default.path(["filmmakers", "members"], personList),
                  experts: _ramda2.default.path(["experts", "members"], personList)
                });
              }
            }),
            _react2.default.createElement(_reactRouterDom.Route, {
              exact: true,
              path: "/the-film/filmmakers",
              render: function render(_ref4) {
                var match = _ref4.match;
                return _react2.default.createElement(_Filmmakers2.default, { filmmakers: personList.filmmakers });
              }
            }),
            _react2.default.createElement(_reactRouterDom.Route, {
              exact: true,
              path: "/the-film/experts",
              render: function render(_ref5) {
                var match = _ref5.match;
                return _react2.default.createElement(_Experts2.default, { experts: personList.experts });
              }
            }),
            _react2.default.createElement(_reactRouterDom.Route, {
              exact: true,
              path: "/screenings",
              render: function render(_ref6) {
                var match = _ref6.match;
                return _react2.default.createElement(_Screenings2.default, screeningsPage);
              }
            }),
            _react2.default.createElement(_reactRouterDom.Route, {
              exact: true,
              path: "/facts",
              render: function render(_ref7) {
                var match = _ref7.match;
                return _react2.default.createElement(_Facts2.default, factsPage);
              }
            }),
            _react2.default.createElement(_reactRouterDom.Route, {
              exact: true,
              path: "/press-news",
              render: function render(_ref8) {
                var match = _ref8.match;
                return _react2.default.createElement(_Press2.default, pressPage);
              }
            }),
            _react2.default.createElement(_reactRouterDom.Route, {
              exact: true,
              path: "/faq",
              render: function render(_ref9) {
                var match = _ref9.match;
                return _react2.default.createElement(_Faqs2.default, faqPage);
              }
            }),
            _react2.default.createElement(_reactRouterDom.Route, {
              exact: true,
              path: "/privacy",
              render: function render(_ref10) {
                var match = _ref10.match;
                return _react2.default.createElement(_TextPage2.default, {
                  className: "text-page--narrow privacy",
                  dangerouslySetInnerHTML: {
                    __html: (0, _marked2.default)(_ramda2.default.prop("privacy", footer))
                  }
                });
              }
            }),
            _react2.default.createElement(_reactRouterDom.Route, {
              exact: true,
              path: "/terms",
              render: function render(_ref11) {
                var match = _ref11.match;
                return _react2.default.createElement(_TextPage2.default, {
                  className: "text-page--narrow terms",
                  dangerouslySetInnerHTML: {
                    __html: (0, _marked2.default)(_ramda2.default.prop("terms", footer))
                  }
                });
              }
            }),
            _react2.default.createElement(_reactRouterDom.Redirect, { to: "/" })
          )
        ),
        billingBlock && _react2.default.createElement(
          "section",
          { className: "billing-block container" },
          _react2.default.createElement(_ContentfulImg2.default, { photo: billingBlock.image })
        ),
        _react2.default.createElement(
          "footer",
          {
            className: "container container--full footer__container",
            style: {
              backgroundImage: "url(\"" + _ramda2.default.path(["img", "file", "url"], footer) + "\")",
              height: _ramda2.default.path(["img", "file", "details", "image", "height"], footer)
            }
          },
          _react2.default.createElement(
            "div",
            { className: "footer" },
            _react2.default.createElement(
              "div",
              { className: "footer__film-title" },
              _react2.default.createElement(
                "small",
                null,
                "The"
              ),
              _react2.default.createElement("br", null),
              "Last Harvest"
            ),
            _react2.default.createElement(
              "div",
              { className: "footer__sub" },
              _react2.default.createElement(
                "div",
                { className: "footer__links" },
                footer.privacy && _react2.default.createElement(
                  _reactRouterDom.Link,
                  { to: "/privacy" },
                  "Privacy Policy"
                ),
                footer.terms && _react2.default.createElement(
                  _reactRouterDom.Link,
                  { to: "/terms" },
                  "Terms of Use"
                )
              ),
              footer.contactEmail && _react2.default.createElement(
                "div",
                { className: "footer__email" },
                _react2.default.createElement(
                  "a",
                  { href: "mailto:" + footer.contactEmail },
                  footer.contactEmail
                )
              ),
              footer.copyright && _react2.default.createElement(
                "div",
                { className: "footer__copyright" },
                footer.copyright
              )
            )
          )
        )
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return App;
}(_react2.default.Component);

// App will update when the route changes


App.propTypes = {
  billingBlock: (0, _propTypes.shape)({
    image: _propTypes2.photo.isRequired
  }).isRequired,
  factsPage: _propTypes.object.isRequired,
  faqPage: _propTypes.object.isRequired,
  footer: (0, _propTypes.shape)({
    img: _propTypes2.photo,
    privacy: _propTypes.string,
    terms: _propTypes.string,
    contactEmail: _propTypes.string,
    copyright: _propTypes.string
  }).isRequired,
  homePage: _propTypes.object.isRequired,
  location: _propTypes.object.isRequired, // react-router via withRouter(App)
  personList: (0, _propTypes.shape)({
    filmmakers: _propTypes2.personList.isRequired,
    experts: _propTypes2.personList.isRequired
  }).isRequired,
  photoGallery: (0, _propTypes.shape)({
    filmStills: _propTypes2.gallery
  }),
  pressPage: _propTypes.object.isRequired,
  screeningsPage: _propTypes.object.isRequired,
  synopsis: _propTypes.object.isRequired,
  theFilmPage: _propTypes.object.isRequired,
  trailer: _propTypes.object.isRequired
};
App.childContextTypes = {
  heroImage: _propTypes2.photo
};
exports.default = (0, _reactRouterDom.withRouter)(App);

},{"./common/marked.js":2,"./common/propTypes.js":3,"./common/scrollHelpers.js":4,"./components/ContentfulImg/ContentfulImg.jsx":6,"./components/Navigation/Navigation.jsx":18,"./pages/Experts/Experts.jsx":21,"./pages/Facts/Facts.jsx":22,"./pages/Faqs/Faqs.jsx":23,"./pages/Filmmakers/Filmmakers.jsx":24,"./pages/Home/Home.jsx":25,"./pages/Press/Press.jsx":26,"./pages/Screenings/Screenings.jsx":27,"./pages/TextPage/TextPage.jsx":28,"./pages/TheFilm/TheFilm.jsx":29,"./routes.js":30,"prop-types":"prop-types","ramda":"ramda","react":"react","react-router-dom":"react-router-dom"}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _marked = require("marked");

var _marked2 = _interopRequireDefault(_marked);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var renderer = new _marked2.default.Renderer();

var linkRenderer = renderer.link;

// https://github.com/markedjs/marked/issues/655#issuecomment-143456762
// open rte links in a new tab
renderer.link = function (href, title, text) {
  var html = linkRenderer.call(renderer, href, title, text);

  // open internal links in the same tab
  // if (href.startsWith("/")) {
  //   return html;
  // }

  // noopener noreferrer is a security risk
  // https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-target-blank.md
  return html.replace(/^<a /, '<a target="_blank" rel="noopener noreferrer" ');
};

_marked2.default.setOptions({
  renderer: renderer,
  breaks: true
});

exports.default = _marked2.default;

},{"marked":"marked"}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.event = exports.personList = exports.person = exports.gallery = exports.photo = undefined;

var _propTypes = require("prop-types");

var photo = exports.photo = (0, _propTypes.shape)({
  file: (0, _propTypes.shape)({
    url: _propTypes.string.isRequired,
    contentType: _propTypes.string,
    filename: _propTypes.string,
    details: (0, _propTypes.shape)({
      size: _propTypes.number,
      image: (0, _propTypes.shape)({
        height: _propTypes.number,
        width: _propTypes.number
      })
    })
  }),
  title: _propTypes.string
});

var gallery = exports.gallery = (0, _propTypes.shape)({
  id: _propTypes.string.isRequired,
  title: _propTypes.string.isRequired,
  photos: (0, _propTypes.arrayOf)(photo).isRequired
});

var person = exports.person = (0, _propTypes.shape)({
  name: _propTypes.string.isRequired,
  bio: _propTypes.string.isRequired,
  shortBio: _propTypes.string.isRequired,
  headshot: photo.isRequired,
  position: _propTypes.string
});

var personList = exports.personList = (0, _propTypes.shape)({
  id: _propTypes.string.isRequired,
  name: _propTypes.string.isRequired,
  members: (0, _propTypes.arrayOf)(person).isRequired,
  heroImage: photo
});

var event = exports.event = (0, _propTypes.shape)({
  title: _propTypes.string.isRequired,
  date: _propTypes.string.isRequired,
  address: _propTypes.string,
  description: _propTypes.string,
  image: photo,
  url: _propTypes.string,
  laurel: photo
});

},{"prop-types":"prop-types"}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOffsetTop = getOffsetTop;
exports.getScrollTop = getScrollTop;
exports.scrollTo = scrollTo;
// Element -> Number
function getOffsetTop(element) {
  var _element$getBoundingC = element.getBoundingClientRect(),
      top = _element$getBoundingC.top;

  var scrollTop = getScrollTop();
  return top + scrollTop;
}

// cross browser schinanigans
function getScrollTop() {
  return document.documentElement.scrollTop || document.body.scrollTop;
}

function scrollTo(top) {
  window.scroll({
    top: top,
    left: 0,
    behavior: "smooth"
  });
}

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slugify = require("slugify");

var _slugify2 = _interopRequireDefault(_slugify);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (string) {
  return (0, _slugify2.default)(string, {
    remove: /[*+~.()'"!:@#]/g,
    lower: true
  });
};

},{"slugify":"slugify"}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _propTypes = require("prop-types");

var _propTypes2 = require("../../common/propTypes.js");

var _getPhotoSrc = require("./getPhotoSrc.js");

var _getPhotoSrc2 = _interopRequireDefault(_getPhotoSrc);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var propTypes = {
  photo: _propTypes2.photo.isRequired,
  width: _propTypes.number,
  height: _propTypes.number
};

var getPhotoProp = _ramda2.default.curry(function (prop, photo) {
  return _ramda2.default.path(["file", "details", "image", prop], photo);
});

/**
 * Component to use as a replacement for <img />.
 * Will take up the white space before the image is loaded
 * (needed for anchor links to go to the right place)
 * https://css-tricks.com/aspect-ratio-boxes/
 */
var ContentfulImage = function ContentfulImage(props) {
  var photo = props.photo,
      rest = _objectWithoutProperties(props, ["photo"]);

  var width = props.width || getPhotoProp("width", photo);
  var height = props.width && props.height ? props.height : "";
  var ratio = getPhotoProp("height", photo) / getPhotoProp("width", photo);
  var src = (0, _getPhotoSrc2.default)({ photo: photo, width: width, height: height });

  // other styles are in css .contentful-img in img.scss
  var img = {
    width: width + "px"
  };

  var aspectRatio = {
    width: "1px",
    marginLeft: "-1px",
    float: "left",
    height: 0,
    paddingTop: "calc(" + ratio + " * 100%)"
  };

  var clear = {
    content: "",
    display: "table",
    clear: "both"
  };

  return _react2.default.createElement(
    "div",
    { className: "contentful-img", style: img },
    _react2.default.createElement("div", { className: "contentful-img__aspect-ratio", style: aspectRatio }),
    _react2.default.createElement("img", _extends({
      src: src,
      alt: _ramda2.default.path(["description"], photo) || _ramda2.default.path(["title"], photo) || _ramda2.default.path(["file", "fileName"], photo),
      width: width,
      height: "auto" // don't specify height for responsive images
    }, rest)),
    _react2.default.createElement("div", { className: "contentful-img__clear", style: clear })
  );
};

ContentfulImage.propTypes = propTypes;

exports.default = ContentfulImage;

},{"../../common/propTypes.js":3,"./getPhotoSrc.js":7,"prop-types":"prop-types","ramda":"ramda","react":"react"}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPhotoSrc;

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Obj -> String
// Obj: { photo: ContentfulPhoto, width: Number, height: Number }
function getPhotoSrc(_ref) {
  var photo = _ref.photo,
      width = _ref.width,
      height = _ref.height;

  var addParams = function addParams(obj) {
    return _ramda2.default.compose(_ramda2.default.when(_ramda2.default.anyPass([_ramda2.default.has("w"), _ramda2.default.has("h")]), _ramda2.default.assoc("fit", "thumb")), // add fit=thumb when there is a w/h
    _ramda2.default.when(function (_) {
      return !_ramda2.default.isNil(height);
    }, _ramda2.default.assoc("h", height)), _ramda2.default.when(function (_) {
      return !_ramda2.default.isNil(width);
    }, _ramda2.default.assoc("w", width))
    // R.assoc("h", height || R.path(["file", "details", "image", "height"], photo))
    // R.assoc("w", width || R.path(["file", "details", "image", "width"], photo))
    )(obj);
  };

  // ?w=${width}&h=${height}&fit=thumb
  var queryString = _ramda2.default.compose(function (qs) {
    return qs.length > 0 ? "?" + qs : "";
  }, _ramda2.default.join("&"), _ramda2.default.map(_ramda2.default.join("=")), _ramda2.default.toPairs, addParams)({});

  var src = _ramda2.default.compose(function (url) {
    return "" + url + queryString;
  }, _ramda2.default.path(["file", "url"]))(photo);

  return src;
}

},{"ramda":"ramda"}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _reactPhotoswipe = require("react-photoswipe");

var _getPhotoSrc = require("../ContentfulImg/getPhotoSrc.js");

var _getPhotoSrc2 = _interopRequireDefault(_getPhotoSrc);

var _propTypes = require("prop-types");

var _propTypes2 = require("../../common/propTypes.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var THUMB_WIDTH = 370;
var THUMB_HEIGHT = 412;

var Gallery = function (_React$Component) {
  _inherits(Gallery, _React$Component);

  function Gallery() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Gallery);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Gallery.__proto__ || Object.getPrototypeOf(Gallery)).call.apply(_ref, [this].concat(args))), _this), _this.getThumbnailContent = function (item) {
      return _react2.default.createElement(
        "div",
        { className: "thumb" },
        _react2.default.createElement(
          "div",
          { className: "thumb__plus" },
          plusSvg
        ),
        _react2.default.createElement(
          "div",
          { className: "thumb__img" },
          _react2.default.createElement("img", { src: item.thumbnail, alt: item.title, width: "100%" })
        )
      );
    }, _this.render = function () {
      var photos = _this.props.photos;


      var photoswipeItems = _ramda2.default.map(function (photo) {
        return {
          src: (0, _getPhotoSrc2.default)({ photo: photo }),
          thumbnail: (0, _getPhotoSrc2.default)({
            photo: photo,
            width: THUMB_WIDTH,
            height: THUMB_HEIGHT
          }),
          w: _ramda2.default.path(["file", "details", "image", "width"], photo),
          h: _ramda2.default.path(["file", "details", "image", "height"], photo),
          title: photo.title
        };
      })(photos);

      // http://photoswipe.com/documentation/options.html
      var options = {
        history: false,

        // ui options
        fullscreenEl: true,
        zoomEl: false,
        shareEl: false,
        counterEl: true,

        // http://photoswipe.com/documentation/faq.html 2nd question
        showHideOpacity: true,
        getThumbBoundsFn: false
      };

      return _react2.default.createElement(
        "div",
        { className: "gallery" },
        _react2.default.createElement(_reactPhotoswipe.PhotoSwipeGallery, {
          items: photoswipeItems,
          options: options,
          thumbnailContent: _this.getThumbnailContent
        })
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return Gallery;
}(_react2.default.Component);

Gallery.propTypes = {
  photos: (0, _propTypes.arrayOf)(_propTypes2.photo)
};
exports.default = Gallery;


var plusSvg = _react2.default.createElement(
  "svg",
  {
    className: "icon icon--plus",
    xmlns: "http://www.w3.org/2000/svg",
    width: "33",
    height: "33",
    viewBox: "0 0 33 33"
  },
  _react2.default.createElement(
    "g",
    null,
    _react2.default.createElement("path", {
      fill: "#FFF",
      d: "M16.5,33 C7.40187,33 0,25.59819 0,16.5 C0,7.40181 7.40187,0 16.5,0 C25.59813,0 33,7.40181 33,16.5 C33,25.59819 25.59813,33 16.5,33 Z"
    }),
    _react2.default.createElement(
      "g",
      { fill: "#000", transform: "translate(7.47 7.56)" },
      _react2.default.createElement("path", { d: "M8.99769,17.97138 C8.59029,17.97138 8.26005,17.6412 8.26005,17.23374 L8.26005,0.759210001 C8.26005,0.351750001 8.59029,0.0215700009 8.99769,0.0215700009 C9.40509,0.0215700009 9.73533,0.351750001 9.73533,0.759210001 L9.73533,17.23374 C9.73533,17.6412 9.40509,17.97138 8.99769,17.97138 Z" }),
      _react2.default.createElement("path", { d: "M17.23503,9.73413 L0.760380001,9.73413 C0.352980001,9.73413 0.0227400009,9.40395 0.0227400009,8.99649 C0.0227400009,8.58903 0.352980001,8.25885 0.760380001,8.25885 L17.23503,8.25885 C17.64243,8.25885 17.97267,8.58903 17.97267,8.99649 C17.97267,9.40395 17.64243,9.73413 17.23503,9.73413 Z" })
    )
  )
);

},{"../../common/propTypes.js":3,"../ContentfulImg/getPhotoSrc.js":7,"prop-types":"prop-types","ramda":"ramda","react":"react","react-photoswipe":"react-photoswipe"}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _React = require("React");

var _React2 = _interopRequireDefault(_React);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require("prop-types");

var _propTypes2 = require("../../common/propTypes.js");

var _lodash = require("lodash.debounce");

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Hero = function (_React$Component) {
  _inherits(Hero, _React$Component);

  function Hero() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Hero);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Hero.__proto__ || Object.getPrototypeOf(Hero)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      // puting useVideo in state instead of in the render method because there
      // could be a mismatch between the client and the server rendered code.
      // useVideo will update on componentDidMount
      useVideo: false
    }, _this.componentDidMount = function () {
      window.addEventListener("resize", _this.reRender);
      _this.reRender(); // to update useVideo
    }, _this.componentWillUnmount = function () {
      window.removeEventListener("resize", _this.reRender);
    }, _this.reRender = (0, _lodash2.default)(function () {
      var heroVideo = _this.props.heroVideo;


      var isMobile = window.matchMedia("(max-width: 900px)").matches;

      // use the video if we have one, and it's not mobile
      _this.setState({
        useVideo: heroVideo && !isMobile
      });
    }, 500), _this.render = function () {
      // get the URL out of a contentful photo
      var photoUrl = _ramda2.default.path(["file", "url"]);

      var _this$props = _this.props,
          isBig = _this$props.isBig,
          children = _this$props.children,
          heroVideo = _this$props.heroVideo;
      var useVideo = _this.state.useVideo;


      var heroImageUrl = photoUrl(_this.props.heroImage) || photoUrl(_this.context.heroImage);

      var classes = (0, _classnames2.default)("hero", {
        "hero--small": !isBig
      });

      // otherwise, use the background image
      var backgroundImageStyle = !useVideo ? { backgroundImage: "url(" + heroImageUrl + ")" } : {};

      return _React2.default.createElement(
        "section",
        { className: "container container--full container--hero" },
        _React2.default.createElement(
          "div",
          { className: classes, style: backgroundImageStyle },
          useVideo && _React2.default.createElement(
            "div",
            { className: "hero__video" },
            _React2.default.createElement(
              "video",
              { loop: true, muted: true, autoPlay: true, className: "hero__video" },
              _React2.default.createElement("source", {
                src: _ramda2.default.path(["file", "url"], heroVideo),
                type: _ramda2.default.path(["file", "contentType"], heroVideo)
              })
            )
          ),
          _React2.default.createElement(
            "div",
            { className: "hero__content" },
            children
          )
        )
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return Hero;
}(_React2.default.Component);

Hero.propTypes = {
  heroImage: _propTypes2.photo,
  heroVideo: _propTypes2.photo,
  isBig: _propTypes.bool,
  children: _propTypes.node
};
Hero.contextTypes = {
  heroImage: _propTypes2.photo
};
Hero.defaultProps = {
  isBig: false
};
exports.default = Hero;

},{"../../common/propTypes.js":3,"React":"React","classnames":"classnames","lodash.debounce":"lodash.debounce","prop-types":"prop-types","ramda":"ramda"}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  return _react2.default.createElement(
    "svg",
    _extends({
      className: "icon icon--arrow",
      xmlns: "http://www.w3.org/2000/svg",
      width: "20",
      height: "16",
      viewBox: "0 0 20 16"
    }, props),
    _react2.default.createElement("path", { d: "M.0075 7.99c0-.3893.2988-.7048.6674-.7048h17.464c.3686 0 .6674.3156.6674.7048 0 .3893-.2988.7049-.6674.7049H.6749c-.3686 0-.6674-.3156-.6674-.7049z" }),
    _react2.default.createElement("path", { d: "M11.7652 15.2874c0-.1805.0651-.3608.1955-.4985l6.428-6.789-6.428-6.789c-.2607-.2752-.2607-.7216 0-.9968.2606-.2753.6832-.2753.9438 0l6.9 7.2875C19.9297 7.6337 20 7.813 20 8s-.0703.3662-.1955.4984l-6.9 7.2874c-.2606.2753-.6832.2753-.9438 0-.1303-.1376-.1955-.3181-.1955-.4984z" })
  );
};

},{"react":"react"}],11:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  return _react2.default.createElement(
    "svg",
    {
      className: "icon icon--download",
      xmlns: "http://www.w3.org/2000/svg",
      width: "18",
      height: "25",
      viewBox: "0 0 18 25"
    },
    _react2.default.createElement("path", { d: "M9.01.0075c.3893 0 .7048.2988.7048.6674v17.464c0 .3686-.3156.6674-.7048.6674-.3893 0-.7049-.2988-.7049-.6674V.6749c0-.3686.3156-.6674.7049-.6674z" }),
    _react2.default.createElement("path", { d: "M1.7126 11.7652c.1805 0 .3608.0651.4985.1955l6.789 6.428 6.789-6.428c.2752-.2607.7216-.2607.9968 0 .2753.2606.2753.6832 0 .9438l-7.2875 6.9C9.3663 19.9297 9.187 20 9 20s-.3662-.0703-.4984-.1955l-7.2874-6.9c-.2753-.2606-.2753-.6832 0-.9438.1376-.1303.3181-.1955.4984-.1955zm15.4997 12.7101H.7376A.7376.7376 0 0 1 0 23.7376.7376.7376 0 0 1 .7376 23h16.4747a.7376.7376 0 0 1 .7376.7376.7376.7376 0 0 1-.7376.7377z" })
  );
};

},{"react":"react"}],12:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propTypes = {
  isMinus: _propTypes.bool
};

var PlusMinus = function PlusMinus(_ref) {
  var _ref$isMinus = _ref.isMinus,
      isMinus = _ref$isMinus === undefined ? false : _ref$isMinus;
  return _react2.default.createElement(
    "svg",
    {
      className: "icon icon--plus",
      xmlns: "http://www.w3.org/2000/svg",
      width: "33",
      height: "33",
      viewBox: "0 0 33 33"
    },
    _react2.default.createElement(
      "g",
      { fill: "none" },
      _react2.default.createElement("path", {
        className: "plus__background",
        fill: "#FFF",
        d: "M16.5 33C7.4019 33 0 25.5982 0 16.5S7.4019 0 16.5 0C25.5981 0 33 7.4018 33 16.5S25.5981 33 16.5 33z"
      }),
      _react2.default.createElement(
        "g",
        { fill: "#000", className: "plus__plus" },
        !isMinus && _react2.default.createElement("path", { d: "M16.4677 25.5314a.7376.7376 0 0 1-.7377-.7377V8.3192a.7376.7376 0 0 1 .7377-.7376.7376.7376 0 0 1 .7376.7376v16.4745a.7376.7376 0 0 1-.7376.7377z" }),
        _react2.default.createElement("path", { d: "M24.705 17.2941H8.2304a.7376.7376 0 0 1-.7377-.7376.7376.7376 0 0 1 .7377-.7377H24.705a.7376.7376 0 0 1 .7377.7377.7376.7376 0 0 1-.7377.7376z" })
      )
    )
  );
};

PlusMinus.propTypes = propTypes;

exports.default = PlusMinus;

},{"prop-types":"prop-types","react":"react"}],13:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var IframeRatio = function (_React$Component) {
  _inherits(IframeRatio, _React$Component);

  function IframeRatio() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, IframeRatio);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = IframeRatio.__proto__ || Object.getPrototypeOf(IframeRatio)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      ratio: 1
    }, _this.componentDidMount = function () {
      // fiture out the ratio and store it in the state
      var _this$el$querySelecto = _this.el.querySelector("iframe"),
          width = _this$el$querySelecto.width,
          height = _this$el$querySelecto.height;

      var nextState = {
        ratio: height / width,
        maxWidth: width
      };

      // set it, then resize the iframe
      _this.setState(nextState, _this.resizeIframe);

      window.addEventListener("resize", _this.resizeIframe);
    }, _this.componentWillUnmount = function () {
      window.removeEventListener("resize", _this.resizeIframe);
    }, _this.resizeIframe = function () {
      var _this$state = _this.state,
          ratio = _this$state.ratio,
          maxWidth = _this$state.maxWidth;

      var iframe = _this.el.querySelector("iframe");
      var width = _ramda2.default.min(maxWidth, _this.el.offsetWidth);
      iframe.setAttribute("height", ratio * width);
      iframe.setAttribute("width", width);
    }, _this.render = function () {
      var _R$omit = _ramda2.default.omit(["ratio"], _this.props),
          children = _R$omit.children,
          otherProps = _objectWithoutProperties(_R$omit, ["children"]);

      return _react2.default.createElement(
        "div",
        _extends({}, otherProps, { ref: function ref(el) {
            return _this.el = el;
          } }),
        children
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return IframeRatio;
}(_react2.default.Component);

IframeRatio.propTypes = {
  children: _propTypes.node
};
exports.default = IframeRatio;

},{"prop-types":"prop-types","ramda":"ramda","react":"react"}],14:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var R = _interopRequireWildcard(_ramda);

var _reactRouterDom = require("react-router-dom");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _SectionNav = require("./SectionNav.jsx");

var _SectionNav2 = _interopRequireDefault(_SectionNav);

var _routes = require("../../routes.js");

var _routes2 = _interopRequireDefault(_routes);

var _propTypes = require("prop-types");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DesktopNav = function (_React$Component) {
  _inherits(DesktopNav, _React$Component);

  function DesktopNav() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, DesktopNav);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = DesktopNav.__proto__ || Object.getPrototypeOf(DesktopNav)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
      var _this$props = _this.props,
          activeSectionId = _this$props.activeSectionId,
          sections = _this$props.sections,
          location = _this$props.location;


      var sectionNav = _react2.default.createElement(_SectionNav2.default, {
        key: "section-nav",
        activeSectionId: activeSectionId,
        sections: sections
      });

      // get the path as an array
      // eg /the-film/filmmakers will return ["the-film", "filmmakers"]
      var getPaths = R.compose(R.reject(R.isEmpty), R.split("/"));

      // given a path, like "/the-film/filmmakers", return "the-film"
      var getTopPath = R.compose(R.head, getPaths);

      // true if the top level route is active
      var isTopActive = function isTopActive(route) {
        return R.equals(getTopPath(route.path), getTopPath(location.pathname));
      };

      // true if the entire path is equal
      // location.pathname does not include the hash, but getPaths does
      var isPathActive = function isPathActive(route) {
        return R.equals(getPaths(route.path), getPaths(location.pathname));
      };

      var desktopNav = _react2.default.createElement(
        "nav",
        { key: "desktop-nav", className: "desktop-nav" },
        _react2.default.createElement(
          "div",
          { className: "nav__wrapper js-desktop-nav" },
          _react2.default.createElement(
            "ul",
            { className: "container nav__container" },
            _routes2.default.map(function (route) {
              var label = route.label,
                  path = route.path,
                  subnav = route.subnav;


              var topClasses = (0, _classnames2.default)({
                "is-active": isTopActive(route)
              });

              return _react2.default.createElement(
                "li",
                { className: "nav__item", key: path },
                _react2.default.createElement(
                  _reactRouterDom.Link,
                  { to: path, className: topClasses },
                  label
                ),
                // only render dropdowns if there is one
                subnav && _react2.default.createElement(
                  "div",
                  { className: "nav__dropdown" },
                  subnav.map(function (subRoute) {
                    var label = subRoute.label,
                        path = subRoute.path;


                    var subRouteClasses = (0, _classnames2.default)({
                      "is-active": isPathActive(subRoute)
                    });

                    return _react2.default.createElement(
                      _reactRouterDom.Link,
                      {
                        to: path,
                        key: path,
                        className: subRouteClasses
                      },
                      label
                    );
                  })
                )
              );
            })
          )
        )
      );

      // invisible space to fill in for the fixed nav
      var navShim = _react2.default.createElement(
        "div",
        { key: "nav-shim", className: "nav__shim" },
        _react2.default.createElement(
          "ul",
          { className: "container nav__container" },
          _routes2.default.map(function (route) {
            var label = route.label,
                path = route.path;

            return _react2.default.createElement(
              "li",
              { className: "nav__item", key: path },
              _react2.default.createElement(
                _reactRouterDom.Link,
                { to: path },
                label
              )
            );
          })
        )
      );

      return [
      // sectionNav,
      navShim, desktopNav];
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return DesktopNav;
}(_react2.default.Component);

DesktopNav.propTypes = {
  activeSectionId: _propTypes.string,
  sections: _propTypes.array,
  location: (0, _propTypes.shape)({
    pathname: _propTypes.string.isRequired
  })
};
exports.default = DesktopNav;

},{"../../routes.js":30,"./SectionNav.jsx":19,"classnames":"classnames","prop-types":"prop-types","ramda":"ramda","react":"react","react-router-dom":"react-router-dom"}],15:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var propTypes = {
  isOpen: _propTypes.bool,
  className: _propTypes.string
};

var Hamburger = function Hamburger(props) {
  var isOpen = props.isOpen,
      className = props.className,
      rest = _objectWithoutProperties(props, ["isOpen", "className"]);

  var classes = (0, _classnames2.default)(className, "hamburger", {
    "is-open": isOpen
  });

  return _react2.default.createElement(
    "div",
    _extends({ className: classes }, rest),
    _react2.default.createElement("span", null),
    _react2.default.createElement("span", null),
    _react2.default.createElement("span", null),
    _react2.default.createElement("span", null)
  );
};

Hamburger.propTypes = propTypes;

exports.default = Hamburger;

},{"classnames":"classnames","prop-types":"prop-types","react":"react"}],16:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _Hamburger = require("./Hamburger.jsx");

var _Hamburger2 = _interopRequireDefault(_Hamburger);

var _MobileSectionNav = require("./MobileSectionNav.jsx");

var _MobileSectionNav2 = _interopRequireDefault(_MobileSectionNav);

var _reactRouterDom = require("react-router-dom");

var _routes = require("../../routes.js");

var _routes2 = _interopRequireDefault(_routes);

var _lodash = require("lodash.throttle");

var _lodash2 = _interopRequireDefault(_lodash);

var _scrollHelpers = require("../../common/scrollHelpers.js");

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MobileNav = function (_React$Component) {
  _inherits(MobileNav, _React$Component);

  function MobileNav() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, MobileNav);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = MobileNav.__proto__ || Object.getPrototypeOf(MobileNav)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isDrawerOpen: false,
      scrollPosition: 0,
      scrollDirection: "" // "up" or "down"
    }, _this.componentDidMount = function () {
      window.addEventListener("scroll", _this.handleScroll);
    }, _this.componentWillUnmount = function () {
      window.removeEventListener("scroll", _this.handleScroll);
    }, _this.handleScroll = (0, _lodash2.default)(function (e) {
      var scrollPosition = (0, _scrollHelpers.getScrollTop)();

      _this.setState(function (prevState, props) {
        return {
          scrollPosition: scrollPosition,
          scrollDirection: scrollPosition - prevState.scrollPosition > 0 ? "down" : "up"
        };
      });
    }, 100), _this.toggleDrawer = function (e) {
      var isDrawerOpen = _this.state.isDrawerOpen;

      _this.setState({ isDrawerOpen: !isDrawerOpen });
    }, _this.closeDrawer = function (e) {
      _this.setState({ isDrawerOpen: false });
    }, _this.render = function () {
      var _this$state = _this.state,
          isDrawerOpen = _this$state.isDrawerOpen,
          scrollDirection = _this$state.scrollDirection,
          scrollPosition = _this$state.scrollPosition;
      var _this$props = _this.props,
          sections = _this$props.sections,
          activeSectionId = _this$props.activeSectionId;


      var mobileNav__container = (0, _classnames2.default)("mobile-nav__container", "js-mobile-nav", {
        // show if the user is scrolling up, or they're near the top
        "is-hidden": !isDrawerOpen && scrollDirection === "down" && scrollPosition > 50
      });

      return _react2.default.createElement(
        "nav",
        { className: (0, _classnames2.default)("mobile-nav", { "is-open": isDrawerOpen }) },
        _react2.default.createElement(
          "div",
          { className: mobileNav__container },
          _react2.default.createElement(
            "div",
            { className: "mobile-nav__hamburger", onClick: _this.toggleDrawer },
            _react2.default.createElement(_Hamburger2.default, { isOpen: isDrawerOpen }),
            " ",
            isDrawerOpen ? "Close" : "Menu"
          ),
          _react2.default.createElement(
            "div",
            { className: "mobile-nav__title" },
            (0, _routes.pathnameTitle)(location.pathname)
          ),
          sections && _react2.default.createElement(
            "div",
            { className: "mobile-nav__section-nav" },
            _react2.default.createElement(_MobileSectionNav2.default, {
              sections: sections,
              activeSectionId: activeSectionId
            })
          )
        ),
        _react2.default.createElement("div", { className: "mobile-nav__overlay", onClick: _this.closeDrawer }),
        _react2.default.createElement(
          "div",
          { className: "mobile-nav__drawer" },
          _react2.default.createElement(
            "ul",
            { onClick: _this.closeDrawer },
            _routes2.default.map(function (route) {
              var label = route.label,
                  path = route.path,
                  subnav = route.subnav,
                  subnavInDrawer = route.subnavInDrawer;

              return _react2.default.createElement(
                "li",
                { className: "mobile-nav__item", key: path },
                _react2.default.createElement(
                  _reactRouterDom.NavLink,
                  { exact: true, to: path },
                  label
                ),
                subnav && subnavInDrawer !== false && _react2.default.createElement(
                  "div",
                  { className: "mobile-nav__subnav-group" },
                  subnav.map(function (subRoute) {
                    var label = subRoute.label,
                        path = subRoute.path;

                    return _react2.default.createElement(
                      _reactRouterDom.NavLink,
                      { exact: true, to: path, key: path },
                      label
                    );
                  })
                )
              );
            })
          )
        )
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return MobileNav;
}(_react2.default.Component);

MobileNav.propTypes = {
  sections: _propTypes.array,
  activeSectionId: _propTypes.string
};
exports.default = MobileNav;

},{"../../common/scrollHelpers.js":4,"../../routes.js":30,"./Hamburger.jsx":15,"./MobileSectionNav.jsx":17,"classnames":"classnames","lodash.throttle":"lodash.throttle","prop-types":"prop-types","react":"react","react-router-dom":"react-router-dom"}],17:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _reactRouterDom = require("react-router-dom");

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MobileSectionNav = function (_React$Component) {
  _inherits(MobileSectionNav, _React$Component);

  function MobileSectionNav() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, MobileSectionNav);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = MobileSectionNav.__proto__ || Object.getPrototypeOf(MobileSectionNav)).call.apply(_ref, [this].concat(args))), _this), _this.componentWillMount = function () {
      _this.links = {};
    }, _this.componentWillReceiveProps = function (nextProps) {
      // scroll to the active section link
      if (_this.props.activeSectionId !== nextProps.activeSectionId) {
        var el = _this.links[nextProps.activeSectionId];

        if (el) {
          var center = el.offsetLeft + el.offsetWidth / 2;
          var scrollLeft = center - _this.container.offsetWidth / 2;

          _this.container.scroll({
            left: scrollLeft,
            behavior: "smooth"
          });
        }
      }
    }, _this.registerLink = function (id) {
      return function (el) {
        _this.links[id] = el;
      };
    }, _this.render = function () {
      var _this$props = _this.props,
          sections = _this$props.sections,
          activeSectionId = _this$props.activeSectionId;


      return (
        // js-mobile-section-nav hook for smooth scroll in App
        _react2.default.createElement(
          "div",
          { className: "mobile-section-nav js-mobile-section-nav" },
          _react2.default.createElement(
            "div",
            {
              className: "mobile-section-nav__items",
              ref: function ref(el) {
                return _this.container = el;
              }
            },
            sections.map(function (section) {
              var classes = (0, _classnames2.default)("mobile-section-nav__item", {
                "is-active": section.id === activeSectionId
              });
              return _react2.default.createElement(
                "div",
                {
                  key: section.id,
                  className: classes,
                  ref: _this.registerLink(section.id)
                },
                _react2.default.createElement(
                  _reactRouterDom.NavLink,
                  { exact: true, to: "#" + section.id, key: section.id },
                  section.title
                )
              );
            })
          )
        )
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return MobileSectionNav;
}(_react2.default.Component);

MobileSectionNav.propTypes = {
  activeSectionId: _propTypes.string,
  sections: (0, _propTypes.arrayOf)((0, _propTypes.shape)({
    title: _propTypes.string,
    id: _propTypes.string,
    top: _propTypes.number
  })).isRequired
};
exports.default = MobileSectionNav;

},{"classnames":"classnames","prop-types":"prop-types","react":"react","react-router-dom":"react-router-dom"}],18:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _lodash = require("lodash.throttle");

var _lodash2 = _interopRequireDefault(_lodash);

var _MobileNav = require("./MobileNav.jsx");

var _MobileNav2 = _interopRequireDefault(_MobileNav);

var _DesktopNav = require("./DesktopNav.jsx");

var _DesktopNav2 = _interopRequireDefault(_DesktopNav);

var _scrollHelpers = require("../../common/scrollHelpers.js");

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Navigation = function (_React$Component) {
  _inherits(Navigation, _React$Component);

  function Navigation() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Navigation);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Navigation.__proto__ || Object.getPrototypeOf(Navigation)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isMobile: false,
      activeSectionId: "",
      sections: []
    }, _this.componentDidMount = function () {
      window.addEventListener("resize", _this.handleResize);
      document.addEventListener("scroll", _this.updateSections);
      _this.handleResize();
      _this.updateSections();
    }, _this.componentWillUnmount = function () {
      window.removeEventListener("resize", _this.handleResize);
      document.removeEventListener("scroll", _this.updateSections);
    }, _this.componentDidUpdate = function (prevProps, prevState) {
      var activeSectionId = _this.state.activeSectionId;
      var location = _this.props.location;

      // update the hash #

      if (_this.state.activeSectionId !== prevState.activeSectionId) {
        var hash = activeSectionId ? "#" + activeSectionId : "";
        var newLocation = window.location.pathname + window.location.search + hash;
        history.replaceState(null, null, newLocation);
      }

      // make sure we update the sections when the page changes
      if (!_ramda2.default.equals(location, prevProps.location)) {
        _this.updateSections();
      }
    }, _this.handleResize = (0, _lodash2.default)(function (e) {
      var windowWidth = document.documentElement.clientWidth;

      _this.setState({
        isMobile: windowWidth < 900
      });
    }, 100), _this.findSections = (0, _lodash2.default)(function () {
      var allSections = [].concat(_toConsumableArray(document.querySelectorAll(".js-section"))); // NodeList to Array

      return allSections.map(function (el) {
        return {
          el: el,
          id: el.getAttribute("id"),
          top: (0, _scrollHelpers.getOffsetTop)(el),
          // using data-title instead of title to prevent tooltip
          title: el.getAttribute("data-title")
        };
      })
      // remove bad sections and warn ME in the console
      .filter(function (_ref2) {
        var el = _ref2.el,
            title = _ref2.title,
            id = _ref2.id;

        // missing a data-title is ok. In this case, the hash will be update,
        // but it won't show up in the MobileSectionNav or SectionNav
        if (!id) {
          console.warn("I found a .js-section without an id!", el);
          return false;
        } else {
          return true;
        }
      });
    }, 1000), _this.updateSections = (0, _lodash2.default)(function () {
      var offset = 100; // magic number
      var sections = _this.findSections();

      var activeSectionObj = sections.reduce(function (active, section) {
        var past = (0, _scrollHelpers.getScrollTop)() - section.top + offset;
        return past >= 0 && past < active.past ? { past: past, section: section } : active;
      }, { past: Infinity });

      // we only want to show sections that have titles in the nav.
      var sectionsForNav = sections.filter(function (section) {
        return section.title;
      });

      _this.setState({
        activeSectionId: _ramda2.default.path(["section", "id"], activeSectionObj),
        sections: sectionsForNav
      });
    }, 100), _this.render = function () {
      var _this$state = _this.state,
          isMobile = _this$state.isMobile,
          activeSectionId = _this$state.activeSectionId,
          sections = _this$state.sections;
      var location = _this.props.location;


      return isMobile ? _react2.default.createElement(_MobileNav2.default, { activeSectionId: activeSectionId, sections: sections }) : _react2.default.createElement(_DesktopNav2.default, {
        key: "desktop-nav",
        activeSectionId: activeSectionId,
        sections: sections,
        location: location
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return Navigation;
}(_react2.default.Component);

Navigation.propTypes = {
  location: _propTypes.object
};
exports.default = Navigation;

},{"../../common/scrollHelpers.js":4,"./DesktopNav.jsx":14,"./MobileNav.jsx":16,"lodash.throttle":"lodash.throttle","prop-types":"prop-types","ramda":"ramda","react":"react"}],19:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _reactRouterDom = require("react-router-dom");

var _propTypes = require("prop-types");

var _scrollHelpers = require("../../common/scrollHelpers.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SectionNav = function (_React$Component) {
  _inherits(SectionNav, _React$Component);

  function SectionNav() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SectionNav);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SectionNav.__proto__ || Object.getPrototypeOf(SectionNav)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
      var _this$props = _this.props,
          activeSectionId = _this$props.activeSectionId,
          sections = _this$props.sections;


      return _react2.default.createElement(
        "div",
        { className: "section-nav" },
        _react2.default.createElement(
          "div",
          {
            className: "section-nav__section is--to-top",
            onClick: function onClick(e) {
              return (0, _scrollHelpers.scrollTo)(0);
            }
          },
          _react2.default.createElement(
            "div",
            { className: "section-nav__title" },
            "Back to top"
          ),
          _react2.default.createElement(
            "svg",
            {
              height: "32",
              className: "octicon octicon-chevron-up",
              viewBox: "0 0 10 16",
              version: "1.1",
              width: "20",
              "aria-hidden": "true"
            },
            _react2.default.createElement("path", { d: "M10 10l-1.5 1.5L5 7.75 1.5 11.5 0 10l5-5z" })
          )
        ),
        sections.map(function (section) {
          var classes = (0, _classnames2.default)("section-nav__section", {
            "is-active": section.id === activeSectionId
          });

          return _react2.default.createElement(
            _reactRouterDom.NavLink,
            {
              className: classes,
              exact: true,
              to: "#" + section.id,
              key: section.id
            },
            _react2.default.createElement(
              "div",
              { className: "section-nav__title" },
              section.title
            )
          );
        })
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return SectionNav;
}(_react2.default.Component);

SectionNav.propTypes = {
  activeSectionId: _propTypes.string,
  sections: (0, _propTypes.arrayOf)((0, _propTypes.shape)({
    title: _propTypes.string,
    id: _propTypes.string,
    top: _propTypes.number
  }))
};
exports.default = SectionNav;

},{"../../common/scrollHelpers.js":4,"classnames":"classnames","prop-types":"prop-types","react":"react","react-router-dom":"react-router-dom"}],20:[function(require,module,exports){
"use strict";

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _reactRouterDom = require("react-router-dom");

var _App = require("./App.jsx");

var _App2 = _interopRequireDefault(_App);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// import { fetchAbout, fetchPeople } from "./services/driscolls.js";

var initialData = JSON.parse(document.getElementById("initial-data").getAttribute("data-json"));

// console.log("initialData", initialData);

// render for testing
// console.error("switch back to hydrate", "local dev enabled");
// ReactDom.render(
_reactDom2.default.hydrate(_react2.default.createElement(
  _reactRouterDom.BrowserRouter,
  null,
  _react2.default.createElement(_App2.default, initialData)
), document.querySelector(".js-mount"));

},{"./App.jsx":1,"react":"react","react-dom":"react-dom","react-router-dom":"react-router-dom"}],21:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _marked = require("marked");

var _marked2 = _interopRequireDefault(_marked);

var _Hero = require("../../components/Hero/Hero.jsx");

var _Hero2 = _interopRequireDefault(_Hero);

var _ContentfulImg = require("../../components/ContentfulImg/ContentfulImg.jsx");

var _ContentfulImg2 = _interopRequireDefault(_ContentfulImg);

var _slugify = require("../../common/slugify.js");

var _slugify2 = _interopRequireDefault(_slugify);

var _propTypes = require("../../common/propTypes.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propTypes = {
  experts: _propTypes.personList.isRequired
};

var Experts = function Experts(props) {
  var experts = props.experts;


  return _react2.default.createElement(
    "div",
    null,
    _react2.default.createElement(
      _Hero2.default,
      { heroImage: experts.heroImage },
      _react2.default.createElement(
        "h4",
        null,
        "The Last Harvest"
      ),
      _react2.default.createElement(
        "h2",
        null,
        experts.name
      )
    ),
    _react2.default.createElement(
      "section",
      { className: "container" },
      _react2.default.createElement(
        "a",
        { href: "/the-film" },
        "The Film"
      ),
      " / ",
      experts.name
    ),
    _react2.default.createElement(
      "section",
      { className: "container" },
      _react2.default.createElement(
        "div",
        { className: "experts" },
        experts.members.map(function (person, i) {
          var name = person.name,
              headshot = person.headshot,
              bio = person.bio,
              position = person.position;

          return _react2.default.createElement(
            "div",
            {
              key: name,
              className: "expert media-object",
              id: (0, _slugify2.default)(name)
            },
            headshot && _react2.default.createElement(
              "div",
              { className: "expert__headshot media-object__img" },
              _react2.default.createElement(_ContentfulImg2.default, { photo: headshot })
            ),
            _react2.default.createElement(
              "div",
              { className: "expert__info media-object__content" },
              _react2.default.createElement(
                "h3",
                { className: "expert__name" },
                name
              ),
              position && _react2.default.createElement(
                "h4",
                { className: "expert__position" },
                position
              ),
              _react2.default.createElement("div", {
                className: "expert__bio",
                dangerouslySetInnerHTML: {
                  __html: (0, _marked2.default)(bio)
                }
              })
            )
          );
        })
      )
    )
  );
};

Experts.propTypes = propTypes;

exports.default = Experts;

},{"../../common/propTypes.js":3,"../../common/slugify.js":5,"../../components/ContentfulImg/ContentfulImg.jsx":6,"../../components/Hero/Hero.jsx":9,"marked":"marked","react":"react"}],22:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _marked = require("../../common/marked.js");

var _marked2 = _interopRequireDefault(_marked);

var _reactAnimateHeight = require("react-animate-height");

var _reactAnimateHeight2 = _interopRequireDefault(_reactAnimateHeight);

var _Hero = require("../../components/Hero/Hero.jsx");

var _Hero2 = _interopRequireDefault(_Hero);

var _PlusMinus = require("../../components/Icon/PlusMinus.jsx");

var _PlusMinus2 = _interopRequireDefault(_PlusMinus);

var _propTypes = require("prop-types");

var _propTypes2 = require("../../common/propTypes.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Facts = function (_React$Component) {
  _inherits(Facts, _React$Component);

  function Facts() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Facts);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Facts.__proto__ || Object.getPrototypeOf(Facts)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isOpen: _ramda2.default.repeat(false, _ramda2.default.path(["props", "facts", "length"], _this) || 0)
    }, _this.handleToggle = function (index) {
      return function (e) {
        var isOpen = _this.state.isOpen;

        _this.setState({
          isOpen: _ramda2.default.adjust(_ramda2.default.not, index, isOpen)
        });
      };
    }, _this.toggleAll = function (shouldBe) {
      return function (e) {
        var isOpen = _this.state.isOpen;

        _this.setState({
          isOpen: _ramda2.default.map(_ramda2.default.always(shouldBe), isOpen)
        });
      };
    }, _this.render = function () {
      var _this$props = _this.props,
          facts = _this$props.facts,
          factsTitle = _this$props.factsTitle,
          factsDescription = _this$props.factsDescription,
          heroImage = _this$props.heroImage;
      var isOpen = _this.state.isOpen;


      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(
          _Hero2.default,
          { heroImage: heroImage },
          _react2.default.createElement(
            "h4",
            null,
            "The Last Harvest"
          ),
          _react2.default.createElement(
            "h2",
            null,
            "The Facts"
          )
        ),
        _react2.default.createElement(
          "section",
          { className: "container" },
          _react2.default.createElement(
            "div",
            { className: "facts__header" },
            _react2.default.createElement(
              "h2",
              { className: "facts__title" },
              factsTitle
            ),
            factsDescription && _react2.default.createElement("p", {
              className: "facts__description",
              dangerouslySetInnerHTML: {
                // https://github.com/chjj/marked/issues/395
                // don't wrap in <p>
                __html: _marked2.default.inlineLexer(factsDescription, [])
              }
            })
          ),
          _react2.default.createElement(
            "div",
            { className: "plus-minus__controls" },
            _react2.default.createElement(
              "button",
              { type: "button", onClick: _this.toggleAll(true) },
              "expand all"
            ),
            " | ",
            _react2.default.createElement(
              "button",
              { type: "button", onClick: _this.toggleAll(false) },
              "collapse all"
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "facts" },
            facts.map(function (faq, i) {
              var title = faq.title,
                  body = faq.body;


              var classes = (0, _classnames2.default)("plus-minus", {
                "is-open": isOpen[i]
              });

              return _react2.default.createElement(
                "div",
                { className: classes, key: i },
                _react2.default.createElement(
                  "button",
                  {
                    type: "button",
                    className: "plus-minus__title",
                    onClick: _this.handleToggle(i)
                  },
                  _react2.default.createElement(_PlusMinus2.default, { isMinus: isOpen[i] }),
                  _react2.default.createElement("div", {
                    className: "plus-minus__title-text",
                    dangerouslySetInnerHTML: {
                      // https://github.com/chjj/marked/issues/395
                      // don't wrap in <p>
                      __html: _marked2.default.inlineLexer(title, [])
                    }
                  })
                ),
                _react2.default.createElement(
                  _reactAnimateHeight2.default,
                  { height: isOpen[i] ? "auto" : 0 },
                  _react2.default.createElement("div", {
                    className: "plus-minus__body",
                    dangerouslySetInnerHTML: {
                      __html: (0, _marked2.default)(body)
                    }
                  })
                )
              );
            })
          )
        )
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return Facts;
}(_react2.default.Component);

Facts.propTypes = {
  factsTitle: _propTypes.string.isRequired,
  factsDescription: _propTypes.string,
  facts: (0, _propTypes.arrayOf)((0, _propTypes.shape)({
    title: _propTypes.string.isRequired,
    body: _propTypes.string.isRequired
  })).isRequired,
  heroImage: _propTypes2.photo
};
exports.default = Facts;

},{"../../common/marked.js":2,"../../common/propTypes.js":3,"../../components/Hero/Hero.jsx":9,"../../components/Icon/PlusMinus.jsx":12,"classnames":"classnames","prop-types":"prop-types","ramda":"ramda","react":"react","react-animate-height":"react-animate-height"}],23:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _marked = require("../../common/marked.js");

var _marked2 = _interopRequireDefault(_marked);

var _reactAnimateHeight = require("react-animate-height");

var _reactAnimateHeight2 = _interopRequireDefault(_reactAnimateHeight);

var _Hero = require("../../components/Hero/Hero.jsx");

var _Hero2 = _interopRequireDefault(_Hero);

var _PlusMinus = require("../../components/Icon/PlusMinus.jsx");

var _PlusMinus2 = _interopRequireDefault(_PlusMinus);

var _propTypes = require("prop-types");

var _propTypes2 = require("../../common/propTypes.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Faqs = function (_React$Component) {
  _inherits(Faqs, _React$Component);

  function Faqs() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Faqs);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Faqs.__proto__ || Object.getPrototypeOf(Faqs)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isOpen: _ramda2.default.repeat(false, _ramda2.default.path(["props", "faqs", "length"], _this) || 0)
    }, _this.handleToggle = function (index) {
      return function (e) {
        var isOpen = _this.state.isOpen;

        _this.setState({
          isOpen: _ramda2.default.adjust(_ramda2.default.not, index, isOpen)
        });
      };
    }, _this.toggleAll = function (shouldBe) {
      return function (e) {
        var isOpen = _this.state.isOpen;

        _this.setState({
          isOpen: _ramda2.default.map(_ramda2.default.always(shouldBe), isOpen)
        });
      };
    }, _this.render = function () {
      var _this$props = _this.props,
          faqs = _this$props.faqs,
          faqsTitle = _this$props.faqsTitle,
          faqsDescription = _this$props.faqsDescription,
          heroImage = _this$props.heroImage;
      var isOpen = _this.state.isOpen;


      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(
          _Hero2.default,
          { heroImage: heroImage },
          _react2.default.createElement(
            "h4",
            null,
            "The Last Harvest"
          ),
          _react2.default.createElement(
            "h2",
            null,
            "FAQ"
          )
        ),
        _react2.default.createElement(
          "section",
          { className: "container" },
          _react2.default.createElement(
            "div",
            { className: "faqs__header" },
            _react2.default.createElement(
              "h2",
              { className: "faqs__title" },
              faqsTitle
            ),
            faqsDescription && _react2.default.createElement("p", {
              className: "faqs__description",
              dangerouslySetInnerHTML: {
                // https://github.com/chjj/marked/issues/395
                // don't wrap in <p>
                __html: _marked2.default.inlineLexer(faqsDescription, [])
              }
            })
          ),
          _react2.default.createElement(
            "div",
            { className: "plus-minus__controls" },
            _react2.default.createElement(
              "button",
              { type: "button", onClick: _this.toggleAll(true) },
              "expand all"
            ),
            " | ",
            _react2.default.createElement(
              "button",
              { type: "button", onClick: _this.toggleAll(false) },
              "collapse all"
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "faqs" },
            faqs.map(function (faq, i) {
              var question = faq.question,
                  answer = faq.answer;


              var classes = (0, _classnames2.default)("plus-minus", {
                "is-open": isOpen[i]
              });

              return _react2.default.createElement(
                "div",
                { className: classes, key: i },
                _react2.default.createElement(
                  "button",
                  {
                    type: "button",
                    className: "plus-minus__title",
                    onClick: _this.handleToggle(i)
                  },
                  _react2.default.createElement(_PlusMinus2.default, { isMinus: isOpen[i] }),
                  _react2.default.createElement("div", {
                    className: "plus-minus__title-text",
                    dangerouslySetInnerHTML: {
                      // https://github.com/chjj/marked/issues/395
                      // don't wrap in <p>
                      __html: _marked2.default.inlineLexer(question, [])
                    }
                  })
                ),
                _react2.default.createElement(
                  _reactAnimateHeight2.default,
                  { height: isOpen[i] ? "auto" : 0 },
                  _react2.default.createElement("div", {
                    className: "plus-minus__body",
                    dangerouslySetInnerHTML: {
                      __html: (0, _marked2.default)(answer)
                    }
                  })
                )
              );
            })
          )
        )
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return Faqs;
}(_react2.default.Component);

Faqs.propTypes = {
  faqsTitle: _propTypes.string.isRequired,
  faqsDescription: _propTypes.string,
  faqs: (0, _propTypes.arrayOf)((0, _propTypes.shape)({
    question: _propTypes.string.isRequired,
    answer: _propTypes.string.isRequired
  })).isRequired,
  heroImage: _propTypes2.photo
};
exports.default = Faqs;

},{"../../common/marked.js":2,"../../common/propTypes.js":3,"../../components/Hero/Hero.jsx":9,"../../components/Icon/PlusMinus.jsx":12,"classnames":"classnames","prop-types":"prop-types","ramda":"ramda","react":"react","react-animate-height":"react-animate-height"}],24:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _marked = require("marked");

var _marked2 = _interopRequireDefault(_marked);

var _Hero = require("../../components/Hero/Hero.jsx");

var _Hero2 = _interopRequireDefault(_Hero);

var _ContentfulImg = require("../../components/ContentfulImg/ContentfulImg.jsx");

var _ContentfulImg2 = _interopRequireDefault(_ContentfulImg);

var _slugify = require("../../common/slugify.js");

var _slugify2 = _interopRequireDefault(_slugify);

var _propTypes = require("../../common/propTypes.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propTypes = {
  filmmakers: _propTypes.personList.isRequired
};

var Filmmakers = function Filmmakers(props) {
  var filmmakers = props.filmmakers;


  return _react2.default.createElement(
    "div",
    null,
    _react2.default.createElement(
      _Hero2.default,
      { heroImage: filmmakers.heroImage },
      _react2.default.createElement(
        "h4",
        null,
        "The Last Harvest"
      ),
      _react2.default.createElement(
        "h2",
        null,
        filmmakers.name
      )
    ),
    _react2.default.createElement(
      "section",
      { className: "container" },
      _react2.default.createElement(
        "a",
        { href: "/the-film" },
        "The Film"
      ),
      " / ",
      filmmakers.name
    ),
    _react2.default.createElement(
      "section",
      { className: "container" },
      _react2.default.createElement(
        "div",
        { className: "filmmakers" },
        filmmakers.members.map(function (person, i) {
          var name = person.name,
              headshot = person.headshot,
              bio = person.bio,
              position = person.position;

          return _react2.default.createElement(
            "div",
            { key: name, className: "filmmaker", id: (0, _slugify2.default)(name) },
            headshot && _react2.default.createElement(_ContentfulImg2.default, {
              photo: headshot,
              className: "filmmaker__headshot"
            }),
            _react2.default.createElement(
              "h3",
              { className: "filmmaker__name" },
              name
            ),
            position && _react2.default.createElement(
              "h4",
              { className: "filmmaker__position" },
              position
            ),
            _react2.default.createElement("div", {
              className: "filmmaker__bio",
              dangerouslySetInnerHTML: {
                __html: (0, _marked2.default)(bio)
              }
            })
          );
        })
      )
    )
  );
};

Filmmakers.propTypes = propTypes;

exports.default = Filmmakers;

},{"../../common/propTypes.js":3,"../../common/slugify.js":5,"../../components/ContentfulImg/ContentfulImg.jsx":6,"../../components/Hero/Hero.jsx":9,"marked":"marked","react":"react"}],25:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _marked = require("marked");

var _marked2 = _interopRequireDefault(_marked);

var _propTypes = require("prop-types");

var _propTypes2 = require("../../common/propTypes.js");

var _reactRouterDom = require("react-router-dom");

var _IframeRatio = require("../../components/IframeRatio/IframeRatio.jsx");

var _IframeRatio2 = _interopRequireDefault(_IframeRatio);

var _ContentfulImg = require("../../components/ContentfulImg/ContentfulImg.jsx");

var _ContentfulImg2 = _interopRequireDefault(_ContentfulImg);

var _Hero = require("../../components/Hero/Hero.jsx");

var _Hero2 = _interopRequireDefault(_Hero);

var _Arrow = require("../../components/Icon/Arrow.jsx");

var _Arrow2 = _interopRequireDefault(_Arrow);

var _slugify = require("../../common/slugify.js");

var _slugify2 = _interopRequireDefault(_slugify);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Home = function (_React$Component) {
  _inherits(Home, _React$Component);

  function Home() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Home);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Home.__proto__ || Object.getPrototypeOf(Home)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
      var _this$props = _this.props,
          filmTagline = _this$props.filmTagline,
          introDescription = _this$props.introDescription,
          heroVideo = _this$props.heroVideo,
          heroImage = _this$props.heroImage,
          vimeoIframeCode = _this$props.vimeoIframeCode,
          synopsis = _this$props.synopsis,
          featuredFilmmaker = _this$props.featuredFilmmaker,
          events = _this$props.events;

      // https://github.com/vimeo/player.js/issues/52
      // const playTrailerFullscreen = () => {
      //   const videoWrapper = document.querySelector(".trailer__iframe iframe");
      //
      //   if (videoWrapper.requestFullscreen) {
      //     videoWrapper.requestFullscreen();
      //   } else if (videoWrapper.mozRequestFullScreen) {
      //     videoWrapper.mozRequestFullScreen();
      //   } else if (videoWrapper.webkitRequestFullscreen) {
      //     videoWrapper.webkitRequestFullscreen();
      //   } else if (videoWrapper.msRequestFullscreen) {
      //     videoWrapper.msRequestFullscreen();
      //   }
      // };

      return _react2.default.createElement(
        "div",
        { className: "home" },
        _react2.default.createElement(
          _Hero2.default,
          { heroVideo: heroVideo, heroImage: heroImage, isBig: true },
          _react2.default.createElement(
            "h1",
            { className: "home__film-title" },
            _react2.default.createElement(
              "small",
              null,
              "The"
            ),
            _react2.default.createElement("br", null),
            "Last Harvest"
          ),
          _react2.default.createElement("p", {
            className: "home__film-tagline",
            dangerouslySetInnerHTML: {
              // https://github.com/chjj/marked/issues/395
              // don't wrap in <p>
              __html: _marked2.default.inlineLexer(filmTagline, [])
            }
          }),
          _react2.default.createElement(
            "div",
            { className: "home__watch-trailer" },
            _react2.default.createElement(
              _reactRouterDom.Link,
              { className: "button button--hero", to: "/#trailer" },
              "Watch the trailer"
            )
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement("p", {
            className: "home__intro-description",
            dangerouslySetInnerHTML: {
              // https://github.com/chjj/marked/issues/395
              // don't wrap in <p>
              __html: _marked2.default.inlineLexer(introDescription, [])
            }
          })
        ),
        _react2.default.createElement(
          "section",
          {
            className: "js-section container home__trailer-container",
            id: "trailer",
            "data-title": "Watch the Trailer"
          },
          _react2.default.createElement(
            "div",
            { className: "trailer" },
            _react2.default.createElement(_IframeRatio2.default, {
              className: "trailer__iframe",
              dangerouslySetInnerHTML: {
                __html: vimeoIframeCode
              }
            })
          )
        ),
        _react2.default.createElement(
          "section",
          {
            id: "laurels",
            className: "js-section home__laurels laurels-grid container",
            "data-title": "Awards"
          },
          _react2.default.createElement(
            "div",
            { className: "laurels-grid__items" },
            events.filter(_ramda2.default.has("laurel")).map(function (event, i) {
              return _react2.default.createElement(
                _reactRouterDom.Link,
                {
                  to: "/screenings#" + (0, _slugify2.default)(event.title),
                  key: i,
                  className: "laurels-grid__item"
                },
                _react2.default.createElement(_ContentfulImg2.default, { photo: event.laurel, width: 600 })
              );
            })
          )
        ),
        _react2.default.createElement(
          "section",
          {
            className: "js-section container container--full synopsis",
            id: "synopsis",
            "data-title": "Synopsis"
          },
          _react2.default.createElement(
            "div",
            { className: "synopsis__top" },
            _react2.default.createElement(
              "h4",
              null,
              "Synopsis"
            ),
            _react2.default.createElement(
              "h2",
              { className: "synopsis__title" },
              "The Last Harvest"
            )
          ),
          synopsis.imageHomePage && _react2.default.createElement("div", {
            className: "synopsis__img",
            style: {
              backgroundImage: "url(\"" + _ramda2.default.path(["imageHomePage", "file", "url"], synopsis) + "\")"
            }
          }),
          _react2.default.createElement(
            "div",
            { className: "synopsis__bottom" },
            _react2.default.createElement("p", {
              className: "synopsis__description",
              dangerouslySetInnerHTML: {
                // https://github.com/chjj/marked/issues/395
                // don't wrap in <p>
                __html: _marked2.default.inlineLexer(synopsis.synopsisShort, [])
              }
            }),
            synopsis.footer && _react2.default.createElement(
              "div",
              { className: "media-object" },
              synopsis.laurel && _react2.default.createElement(
                "div",
                { className: "media-object__img" },
                _react2.default.createElement(_ContentfulImg2.default, { photo: _ramda2.default.path(["laurel"], synopsis) })
              ),
              _react2.default.createElement("div", {
                className: "synopsis__footer media-object__text",
                dangerouslySetInnerHTML: {
                  // https://github.com/chjj/marked/issues/395
                  // don't wrap in <p>
                  __html: _marked2.default.inlineLexer(synopsis.footer, [])
                }
              })
            )
          )
        ),
        _react2.default.createElement(
          "section",
          {
            className: "js-section container container--featured-filmmaker",
            id: "filmmakers",
            "data-title": "The Filmmakers"
          },
          _react2.default.createElement(
            "div",
            { className: "featured-filmmaker" },
            _react2.default.createElement(
              "div",
              { className: "featured-filmmaker__info" },
              _react2.default.createElement(
                "h4",
                null,
                "Cast & Crew"
              ),
              _react2.default.createElement(
                "h2",
                null,
                "The Filmmakers"
              ),
              _react2.default.createElement(
                "h3",
                { className: "featured-filmmaker__name" },
                _ramda2.default.prop("name", featuredFilmmaker)
              ),
              _react2.default.createElement(
                "h4",
                { className: "featured-filmmaker__position below-h3" },
                _ramda2.default.prop("position", featuredFilmmaker)
              ),
              _react2.default.createElement("p", {
                className: "featured-filmmaker__bio",
                dangerouslySetInnerHTML: {
                  // https://github.com/chjj/marked/issues/395
                  // don't wrap in <p>
                  __html: _marked2.default.inlineLexer(featuredFilmmaker.bio, [])
                }
              }),
              _react2.default.createElement(
                "div",
                { className: "featured-filmmaker__button" },
                _react2.default.createElement(
                  _reactRouterDom.Link,
                  { to: "/the-film/filmmakers/", className: "button" },
                  "Meet the creators ",
                  _react2.default.createElement(_Arrow2.default, null)
                )
              )
            ),
            featuredFilmmaker.headshot && _react2.default.createElement(
              "div",
              { className: "featured-filmmaker__image" },
              _react2.default.createElement(_ContentfulImg2.default, {
                className: "bio__headshot",
                photo: _ramda2.default.path(["headshot"], featuredFilmmaker)
              })
            )
          )
        )
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return Home;
}(_react2.default.Component);

Home.propTypes = {
  filmTagline: _propTypes.string.isRequired,
  introDescription: _propTypes.string.isRequired,
  vimeoIframeCode: _propTypes.string.isRequired,
  heroImage: _propTypes2.photo.isRequired,
  heroVideo: _propTypes2.photo.isRequired,
  synopsis: (0, _propTypes.shape)({
    synopsisShort: _propTypes.string.isRequired,
    footer: _propTypes.string,
    imageHomePage: _propTypes2.photo,
    imageInline: _propTypes2.photo,
    laurel: _propTypes2.photo
  }).isRequired,
  featuredFilmmaker: (0, _propTypes.shape)({
    name: _propTypes.string.isRequired,
    headshot: _propTypes2.photo.isRequired,
    bio: _propTypes.string.isRequired
  }),
  events: (0, _propTypes.arrayOf)(_propTypes2.event)
};
exports.default = Home;

},{"../../common/propTypes.js":3,"../../common/slugify.js":5,"../../components/ContentfulImg/ContentfulImg.jsx":6,"../../components/Hero/Hero.jsx":9,"../../components/Icon/Arrow.jsx":10,"../../components/IframeRatio/IframeRatio.jsx":13,"marked":"marked","prop-types":"prop-types","ramda":"ramda","react":"react","react-router-dom":"react-router-dom"}],26:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _marked = require("marked");

var _marked2 = _interopRequireDefault(_marked);

var _Hero = require("../../components/Hero/Hero.jsx");

var _Hero2 = _interopRequireDefault(_Hero);

var _Download = require("../../components/Icon/Download.jsx");

var _Download2 = _interopRequireDefault(_Download);

var _ContentfulImg = require("../../components/ContentfulImg/ContentfulImg.jsx");

var _ContentfulImg2 = _interopRequireDefault(_ContentfulImg);

var _propTypes = require("prop-types");

var _propTypes2 = require("../../common/propTypes.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propTypes = {
  introText: _propTypes.string.isRequired,
  heroImage: _propTypes2.photo,
  posterPreview: _propTypes2.photo.isRequired,
  posterDownload: _propTypes2.photo.isRequired,
  pressKitZip: _propTypes2.photo,
  pressInquiriesBlurb: _propTypes.string,
  pressInquiriesContactName: _propTypes.string,
  pressInquiriesContactTitle: _propTypes.string,
  pressInquiriesContactEmail: _propTypes.string
};

var Press = function Press(props) {
  var introText = props.introText,
      heroImage = props.heroImage,
      posterPreview = props.posterPreview,
      posterDownload = props.posterDownload,
      pressKitZip = props.pressKitZip,
      pressInquiriesBlurb = props.pressInquiriesBlurb,
      pressInquiriesContactName = props.pressInquiriesContactName,
      pressInquiriesContactTitle = props.pressInquiriesContactTitle,
      pressInquiriesContactEmail = props.pressInquiriesContactEmail;

  // const posterSize = R.compose(
  //   size => `${size}MB`,
  //   size => size / 100,
  //   Math.round,
  //   size => size / 10000,
  //   R.defaultTo(0),
  //   R.path(["file", "details", "size"])
  // )(posterDownload);

  var posterType = _ramda2.default.compose(_ramda2.default.toUpper, _ramda2.default.replace(/.*\//, ""), _ramda2.default.defaultTo(""), _ramda2.default.path(["file", "contentType"]))(posterDownload);

  // const posterDimensions = R.compose(
  //   R.unless(R.isNil, ({ width, height }) => `${width}x${height}`),
  //   R.path(["file", "details", "image"])
  // )(posterDownload);

  return _react2.default.createElement(
    "div",
    { className: "press__container" },
    _react2.default.createElement(
      _Hero2.default,
      { heroImage: heroImage },
      _react2.default.createElement(
        "h4",
        null,
        "The Last Harvest"
      ),
      _react2.default.createElement(
        "h2",
        null,
        "Press and News"
      )
    ),
    _react2.default.createElement(
      "section",
      { className: "container" },
      _react2.default.createElement("div", {
        className: "press__intro-text",
        dangerouslySetInnerHTML: {
          __html: (0, _marked2.default)(introText)
        }
      })
    ),
    _react2.default.createElement(
      "section",
      {
        className: "js-section container",
        id: "press-inquiries",
        "data-title": "Press Inquiries"
      },
      _react2.default.createElement(
        "div",
        { className: "press__inquiries" },
        _react2.default.createElement(
          "h4",
          null,
          "Press Inquiries"
        ),
        pressInquiriesBlurb && _react2.default.createElement("div", {
          className: "press__inquiries-blurb",
          dangerouslySetInnerHTML: {
            __html: (0, _marked2.default)(pressInquiriesBlurb)
          }
        }),
        pressInquiriesContactName && _react2.default.createElement(
          "div",
          { className: "press__inquiries-name" },
          pressInquiriesContactName
        ),
        pressInquiriesContactTitle && _react2.default.createElement(
          "div",
          { className: "press__inquiries-title" },
          pressInquiriesContactTitle
        ),
        pressInquiriesContactEmail && _react2.default.createElement(
          "div",
          { className: "press__inquiries-email" },
          pressInquiriesContactEmail && _react2.default.createElement(
            "a",
            { href: "mailto:" + pressInquiriesContactEmail },
            pressInquiriesContactEmail
          )
        )
      )
    ),
    _react2.default.createElement(
      "section",
      {
        className: "js-section container container--full",
        id: "press",
        "data-title": "Press Kit"
      },
      pressKitZip && _react2.default.createElement(
        "div",
        { className: "press__kit" },
        _react2.default.createElement(
          "h4",
          null,
          "Press"
        ),
        _react2.default.createElement(
          "a",
          {
            download: true // only works on same origin urls, using target _blank instead
            , target: "_blank",
            href: _ramda2.default.path(["file", "url"], pressKitZip),
            className: "button button--white"
          },
          "Download Press Kit ",
          _react2.default.createElement(_Download2.default, null)
        )
      )
    ),
    posterPreview && posterDownload && _react2.default.createElement(
      "section",
      {
        className: "js-section container",
        id: "film-art",
        "data-title": "Film Art"
      },
      _react2.default.createElement(
        "div",
        { className: "press__film-art" },
        _react2.default.createElement(
          "h4",
          null,
          "Film Art"
        ),
        _react2.default.createElement(
          "h2",
          null,
          "Poster"
        ),
        _react2.default.createElement(
          "div",
          { className: "press__poster" },
          _react2.default.createElement(_ContentfulImg2.default, { photo: posterPreview }),
          _react2.default.createElement(
            "div",
            { className: "press__poster-overlay" },
            _react2.default.createElement(
              "h3",
              null,
              "Download Poster"
            ),
            _react2.default.createElement(
              "h4",
              null,
              "Film Art"
            ),
            _react2.default.createElement(
              "div",
              { className: "press__poster-description" },
              _react2.default.createElement(
                "div",
                null,
                "The Last Harvest Official Poster"
              ),
              _react2.default.createElement(
                "div",
                null,
                posterType
              )
            ),
            _react2.default.createElement(
              "a",
              {
                download: true // only works on same origin urls, using target _blank instead
                , target: "_blank",
                className: "button button--white button--download",
                href: _ramda2.default.path(["file", "url"], posterDownload)
              },
              "Download Poster ",
              _react2.default.createElement(_Download2.default, null)
            )
          )
        )
      )
    )
  );
};

Press.propTypes = propTypes;

exports.default = Press;

},{"../../common/propTypes.js":3,"../../components/ContentfulImg/ContentfulImg.jsx":6,"../../components/Hero/Hero.jsx":9,"../../components/Icon/Download.jsx":11,"marked":"marked","prop-types":"prop-types","ramda":"ramda","react":"react"}],27:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = require("../../common/propTypes.js");

var _marked = require("../../common/marked.js");

var _marked2 = _interopRequireDefault(_marked);

var _slugify = require("../../common/slugify.js");

var _slugify2 = _interopRequireDefault(_slugify);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _ContentfulImg = require("../../components/ContentfulImg/ContentfulImg.jsx");

var _ContentfulImg2 = _interopRequireDefault(_ContentfulImg);

var _Hero = require("../../components/Hero/Hero.jsx");

var _Hero2 = _interopRequireDefault(_Hero);

var _Arrow = require("../../components/Icon/Arrow.jsx");

var _Arrow2 = _interopRequireDefault(_Arrow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propTypes = {
  title: _propTypes.string,
  heroImage: _propTypes2.photo,
  description: _propTypes.string,
  events: (0, _propTypes.arrayOf)(_propTypes2.event)
};

var Screenings = function Screenings(props) {
  var description = props.description,
      heroImage = props.heroImage,
      title = props.title,
      events = props.events;

  var today = new Date(Date.now());

  var activeEvents = events.filter(function (event) {
    return !(event.expirationDate && new Date(event.expirationDate) <= today);
  });

  var inactiveEvents = events.filter(function (event) {
    return event.expirationDate && new Date(event.expirationDate) <= today;
  });

  var renderEvents = function renderEvents(events, expired) {
    var classes = (0, _classnames2.default)("event media-object js-section", {
      "event--expired": expired
    });

    return events.map(function (event, i) {
      var title = event.title,
          date = event.date,
          address = event.address,
          description = event.description,
          image = event.image,
          url = event.url;


      return _react2.default.createElement(
        "div",
        { key: i, className: classes, id: (0, _slugify2.default)(title) },
        _react2.default.createElement(
          "div",
          { className: "media-object__img" },
          _react2.default.createElement(
            "div",
            { className: "event__image" },
            _react2.default.createElement(_ContentfulImg2.default, { photo: image })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "media-object__text" },
          _react2.default.createElement(
            "div",
            { className: "event__title" },
            title
          ),
          date && _react2.default.createElement(
            "div",
            { className: "event__date" },
            date
          ),
          address && _react2.default.createElement(
            "div",
            { className: "event__address" },
            address
          ),
          description && _react2.default.createElement(
            "div",
            { className: "event__description" },
            description
          ),
          url && !expired && _react2.default.createElement(
            "div",
            { className: "event__button" },
            _react2.default.createElement(
              "a",
              {
                href: url,
                target: "_blank",
                rel: "noreferrer noopener",
                className: "button button--tan"
              },
              "Purchase tickets here ",
              _react2.default.createElement(_Arrow2.default, null)
            )
          )
        )
      );
    });
  };

  return _react2.default.createElement(
    "div",
    { className: "screenings" },
    _react2.default.createElement(
      _Hero2.default,
      { heroImage: heroImage },
      _react2.default.createElement(
        "h4",
        null,
        "The Last Harvest"
      ),
      _react2.default.createElement(
        "h2",
        null,
        title || "Screenings"
      )
    ),
    _react2.default.createElement(
      "section",
      { className: "container" },
      _react2.default.createElement("div", {
        className: "screenings__description",
        dangerouslySetInnerHTML: { __html: (0, _marked2.default)(description) }
      })
    ),
    activeEvents.length !== 0 && _react2.default.createElement(
      "section",
      { className: "container screenings__events" },
      renderEvents(activeEvents, false)
    ),
    inactiveEvents.length !== 0 && _react2.default.createElement(
      "section",
      { className: "container screenings__events screenings__events--past" },
      _react2.default.createElement(
        "h3",
        { className: "screenings__past-event" },
        "Past Events"
      ),
      renderEvents(inactiveEvents, true)
    )
  );
};

Screenings.propTypes = propTypes;

exports.default = Screenings;

},{"../../common/marked.js":2,"../../common/propTypes.js":3,"../../common/slugify.js":5,"../../components/ContentfulImg/ContentfulImg.jsx":6,"../../components/Hero/Hero.jsx":9,"../../components/Icon/Arrow.jsx":10,"classnames":"classnames","prop-types":"prop-types","react":"react"}],28:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var propTypes = {
  children: _propTypes.node,
  className: _propTypes.string
};

var TextPage = function TextPage(props) {
  var children = props.children,
      className = props.className,
      rest = _objectWithoutProperties(props, ["children", "className"]);

  var classes = (0, _classnames2.default)("text-page", className);

  return _react2.default.createElement(
    "div",
    { className: classes },
    _react2.default.createElement(
      "div",
      { className: "container" },
      _react2.default.createElement(
        "div",
        _extends({ className: "text-page__content" }, rest),
        children
      )
    )
  );
};

TextPage.propTypes = propTypes;

exports.default = TextPage;

},{"classnames":"classnames","prop-types":"prop-types","react":"react"}],29:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _marked = require("marked");

var _marked2 = _interopRequireDefault(_marked);

var _Gallery = require("../../components/Gallery/Gallery.jsx");

var _Gallery2 = _interopRequireDefault(_Gallery);

var _Hero = require("../../components/Hero/Hero.jsx");

var _Hero2 = _interopRequireDefault(_Hero);

var _Arrow = require("../../components/Icon/Arrow.jsx");

var _Arrow2 = _interopRequireDefault(_Arrow);

var _ContentfulImg = require("../../components/ContentfulImg/ContentfulImg.jsx");

var _ContentfulImg2 = _interopRequireDefault(_ContentfulImg);

var _reactRouterDom = require("react-router-dom");

var _slugify = require("../../common/slugify.js");

var _slugify2 = _interopRequireDefault(_slugify);

var _propTypes = require("prop-types");

var _propTypes2 = require("../../common/propTypes.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propTypes = {
  synopsis: _propTypes.string.isRequired,
  filmStills: _propTypes2.gallery.isRequired,
  filmmakers: (0, _propTypes.arrayOf)(_propTypes2.person).isRequired,
  experts: (0, _propTypes.arrayOf)(_propTypes2.person).isRequired,
  imageInline: _propTypes2.photo.isRequired,
  heroImage: _propTypes2.photo
};

var TheFilm = function TheFilm(props) {
  var filmStills = props.filmStills,
      filmmakers = props.filmmakers,
      experts = props.experts,
      synopsis = props.synopsis,
      imageInline = props.imageInline,
      heroImage = props.heroImage;


  return _react2.default.createElement(
    "div",
    { className: "the-film" },
    _react2.default.createElement(
      _Hero2.default,
      { heroImage: heroImage },
      _react2.default.createElement(
        "h4",
        null,
        "The Last Harvest"
      ),
      _react2.default.createElement(
        "h2",
        null,
        "The Film"
      )
    ),
    _react2.default.createElement(
      "section",
      {
        className: "js-section container",
        id: "synopsis",
        "data-title": "Synopsis"
      },
      _react2.default.createElement(
        "div",
        { className: "the-film__title" },
        _react2.default.createElement(
          "h4",
          null,
          "Synopsis"
        ),
        _react2.default.createElement(
          "h2",
          null,
          _react2.default.createElement(
            "span",
            null,
            "You can't grow"
          ),
          " ",
          _react2.default.createElement(
            "span",
            null,
            "without change"
          )
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "the-film__synopsis" },
        _react2.default.createElement(
          "div",
          { className: "the-film__floating-img" },
          _react2.default.createElement(_ContentfulImg2.default, { photo: imageInline })
        ),
        _react2.default.createElement("div", { dangerouslySetInnerHTML: { __html: (0, _marked2.default)(synopsis) } })
      )
    ),
    _react2.default.createElement(
      "section",
      {
        className: "js-section container",
        id: "cast-crew",
        "data-title": "Cast and Crew"
      },
      _react2.default.createElement(
        "div",
        { className: "the-film__title" },
        _react2.default.createElement(
          "h4",
          null,
          "The Last Harvest"
        ),
        _react2.default.createElement(
          "h2",
          null,
          "Cast and Crew"
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "the-film__title is-black" },
        _react2.default.createElement(
          "h4",
          null,
          "The Filmmakers"
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "the-film__person-list" },
        filmmakers && filmmakers.map(renderPerson("/the-film/filmmakers"))
      ),
      _react2.default.createElement(
        "div",
        { className: "the-film__button" },
        _react2.default.createElement(
          _reactRouterDom.Link,
          { to: "/the-film/filmmakers", className: "button" },
          "Meet the Creators ",
          _react2.default.createElement(_Arrow2.default, null)
        )
      )
    ),
    _react2.default.createElement(
      "section",
      { className: "container" },
      _react2.default.createElement(
        "div",
        { className: "the-film__title is-black" },
        _react2.default.createElement(
          "h4",
          null,
          "The Experts"
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "the-film__person-list" },
        experts && experts.map(renderPerson("/the-film/experts"))
      ),
      _react2.default.createElement(
        "div",
        { className: "the-film__button" },
        _react2.default.createElement(
          _reactRouterDom.Link,
          { to: "/the-film/experts", className: "button" },
          "Meet the Experts ",
          _react2.default.createElement(_Arrow2.default, null)
        )
      )
    ),
    _react2.default.createElement(
      "section",
      {
        className: "js-section container",
        id: "gallery",
        "data-title": "Gallery"
      },
      _react2.default.createElement(
        "div",
        { className: "the-film__title" },
        _react2.default.createElement(
          "h4",
          null,
          "Gallery"
        ),
        _react2.default.createElement(
          "h2",
          null,
          "Film stills"
        )
      ),
      filmStills && _react2.default.createElement(_Gallery2.default, filmStills)
    )
  );
};

var renderPerson = function renderPerson(url) {
  return function (person) {
    return _react2.default.createElement(
      "a",
      {
        className: "person-hover",
        key: person.name,
        href: url + "/#" + (0, _slugify2.default)(person.name)
      },
      _react2.default.createElement(_ContentfulImg2.default, { photo: _ramda2.default.path(["headshot"], person), width: 370 }),
      _react2.default.createElement(
        "div",
        { className: "person-hover__info" },
        _react2.default.createElement(
          "div",
          { className: "person-hover__name" },
          person.name
        ),
        person.position && _react2.default.createElement(
          "div",
          { className: "person-hover__position" },
          person.position
        ),
        _react2.default.createElement(
          "div",
          { className: "person-hover__bio" },
          person.shortBio
        )
      )
    );
  };
};

TheFilm.propTypes = propTypes;

exports.default = TheFilm;

},{"../../common/propTypes.js":3,"../../common/slugify.js":5,"../../components/ContentfulImg/ContentfulImg.jsx":6,"../../components/Gallery/Gallery.jsx":8,"../../components/Hero/Hero.jsx":9,"../../components/Icon/Arrow.jsx":10,"marked":"marked","prop-types":"prop-types","ramda":"ramda","react":"react","react-router-dom":"react-router-dom"}],30:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pathnameTitle = pathnameTitle;
exports.pathnamePageTitle = pathnamePageTitle;

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _reactRouterDom = require("react-router-dom");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var routes = [{
  label: "Home",
  path: "/",
  subnavInDrawer: false,
  subnav: [{
    label: "Watch the trailer",
    path: "/#trailer"
  }, {
    label: "Awards",
    path: "/#laurels"
  }, {
    label: "Synopsis",
    path: "/#synopsis"
  }, {
    label: "The Filmmakers",
    path: "/#filmmakers"
  }]
}, {
  label: "The Film",
  path: "/the-film/",
  subnav: [{
    label: "Synopsis",
    path: "/the-film/#synopsis"
  }, {
    label: "The Filmmakers",
    path: "/the-film/filmmakers/"
  }, {
    label: "The Experts",
    path: "/the-film/experts"
  }, {
    label: "Film Stills",
    path: "/the-film/#gallery"
  }]
}, {
  label: "Screenings",
  path: "/screenings/"
}, {
  label: "The Facts",
  path: "/facts/"
}, {
  label: "Press & News",
  path: "/press-news/",
  subnav: [
  // HACK for IE, these are in reverse order because of
  // flex-direction: row-reverse in .nav__dropdown
  {
    label: "Film Art",
    path: "/press-news/#film-art"
  }, {
    label: "Press",
    path: "/press-news/#press"
  }, {
    label: "Press Inquiries",
    path: "/press-news/#press-inquiries"
  }]
}, {
  label: "FAQ",
  path: "/faq/"
}];

// so the functions below can find page titles
var allRoutes = _ramda2.default.compose(_ramda2.default.concat([{
  label: "Privacy Policy",
  path: "/privacy/"
}, {
  label: "Term of Use",
  path: "/terms/"
}]),
// include all sub pages in this list
_ramda2.default.chain(function (path) {
  return [_ramda2.default.omit(["subnav"], path)].concat(_toConsumableArray(path.subnav || []));
}))(routes);

/**
 * @param  {String} pathname: the active pathname
 * @return {String} the label of the active pathname
 */
function pathnameTitle(pathname) {

  return _ramda2.default.compose(_ramda2.default.defaultTo(""), _ramda2.default.prop("label"), _ramda2.default.find(function (route) {
    return (0, _reactRouterDom.matchPath)(route.path, {
      path: pathname,
      exact: true,
      strict: false
    });
  }))(allRoutes);
}

/**
 * @param  {String} pathname: the active pathname
 * @return {String} the label of the active pathname with The Last Harvest
 */
function pathnamePageTitle(pathname) {
  return _ramda2.default.compose(function (suffix) {
    return "The Last Harvest" + suffix;
  }, function (label) {
    return !label || label === "Home" ? "" : " - " + label;
  }, pathnameTitle)(pathname);
}

exports.default = routes;

},{"ramda":"ramda","react-router-dom":"react-router-dom"}]},{},[20]);
